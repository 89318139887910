import React from 'react';
import { useServices } from "../core/context/services";

export type LaunchTokenData = Partial<{
  branding: boolean;
  canvasPlacement: string;
  contextId: string;
  exp: number;
  platformProductFamilyCode: string;
  registration: string;
  sub: string;
  title: string;
}>;

export const useLaunchTokenData = () => {
  const {launchToken} = useServices();

  return React.useMemo((): LaunchTokenData => {
    if (!launchToken) return {};

    // https://stackoverflow.com/a/38552302/14809536
    const base64Url = launchToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const token: { sub: string } = JSON.parse(jsonPayload);

    try {
      // transitioning launch token parameters out of json encoded sub claim
      // and into their own claims of the token. during transition try to decode
      // sub and apply it to the token data so it works either way.
      Object.assign(token, JSON.parse(token.sub));
    } catch { } // eslint-disable-line no-empty

    return token;
  }, [launchToken]);
};
