import React from 'react';
import debounce from 'lodash/debounce';
import { colors, NavBar } from '@openstax/ui-components';
import styled from 'styled-components';
import { TextResizer } from '../../components/TextResizer';
import { TextResizerValue } from "../../constants";
import useHelpMenu from '../../components/HelpMenu';
import { useUserMetadata } from '../../user/utils/user-metadata';
import KebabMenu from '../../components/KebabMenu';
import type {
  StatefulAssignmentResponse
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student';
import { FetchStateType } from '@openstax/ts-utils/fetch';

const StyledNavBar = styled(NavBar)`
  h1 {
    max-width: 33.6rem;
    font-size: 1.6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${colors.palette.neutralDarker};

    @media screen and (max-width: 41rem) {
      max-width: 21.6rem;
    }
  }
`;
const ButtonGroup = styled.span`
  display: flex;
`;

export const Activity = ({ title, src, onChangeTextSize, textSize, isTextResizable, assignment }: {
  title: string;
  src: HTMLIFrameElement['src'];
  textSize?: TextResizerValue;
  onChangeTextSize: (value: TextResizerValue) => void;
  isTextResizable?: boolean;
  assignment: StatefulAssignmentResponse;
}) => {
  const [userMetadata, saveUserMetadata] = useUserMetadata();
  const userTextSize = React.useMemo(() => userMetadata.type === FetchStateType.SUCCESS
      ? userMetadata.data.textSize
      : undefined
  , [userMetadata]);

  const debounceTextChange = React.useMemo(() => debounce(
    (size: TextResizerValue) => saveUserMetadata({textSize: size}),
    500
  ), [saveUserMetadata]);

  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const iframeOrigin = React.useMemo(() => new URL(src).origin, [src]);
  const [HelpMenu, ContactFormIframe] = useHelpMenu();

  React.useEffect(() => {
    if ((userTextSize === textSize ) || (textSize === undefined) || (userMetadata.type === FetchStateType.LOADING)) {
      return;
    }
    debounceTextChange(textSize as TextResizerValue);
  }, [textSize, debounceTextChange, userTextSize, userMetadata.type]);

  return <>
    <StyledNavBar>
      <h1>{title}</h1>
      <ButtonGroup>
        {isTextResizable
          ? <TextResizer textSize={textSize ?? userTextSize ?? 0} setTextSize={(value) => {
            iframeRef.current?.contentWindow?.postMessage({type: 'TextSizeUpdate', value}, iframeOrigin);
            onChangeTextSize(value);
          }} />
          : null}
        <HelpMenu id={assignment.id} />
        <KebabMenu scope={assignment.scope} />
      </ButtonGroup>
    </StyledNavBar>
    <ContactFormIframe />
    <iframe
      ref={iframeRef}
      style={{ flex: 1 }}
      title={title}
      allowFullScreen
      allow="autoplay"
      src={src} />
  </>;
};
