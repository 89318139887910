import styled from 'styled-components';
import * as UI from '@openstax/ui-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
  background-color: #f9f9f9;
`;

const Message = styled.p`
  margin-bottom: 5rem;
`;

const ActivationError = ({dismiss}: {dismiss?: () => void}) => {

  const errorMessage = dismiss
    ? `Please contact your instructor. You may lose access to this course if it is not activated.`
    : `Please contact your instructor. You will not be able to access your course until it is activated.`
  ;

  return (
    <Container>
      <UI.H2>This course has not yet been activated by an instructor.</UI.H2>
      <Message>{errorMessage}</Message>
      {dismiss
        ? <UI.Button variant="primary" onClick={dismiss}>Continue to assignment</UI.Button>
        : null
      }
    </Container>
  );
};

export {ActivationError};
