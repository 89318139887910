import {
  Button,
  ButtonLink,
  Overlay,
  OverlayBody,
  OverlayWrapper
} from "@openstax/ui-components";
import { UserMetadataPayload } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/user";
import React from "react";
import styled from "styled-components";
import { BrowserServices } from "../../..";
import { useCreateCredentialsPopup } from "../../hooks/useRecoveryCredentials";
import { ConfirmDeclineRecoveryCredentials } from "./ConfirmDeclineRecoveryCredentials";

const maskMargin = '2rem';
const calloutColor = '#FDBD3E';
const mobileMediaQuery = '@media only screen and (max-width: 399px)';
const StyledOverlay = styled(Overlay)`
  top: ${maskMargin};
  bottom: ${maskMargin};
  left: ${maskMargin};
  right: ${maskMargin};
  height: calc(100% - (${maskMargin} * 2));
  width: calc(100% - (${maskMargin} * 2));

  ${OverlayWrapper} {
    padding: 4.8rem;
  }

  ${OverlayBody} {
    flex-direction: column;
    text-align: center;
  }

  h1 {
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 4rem;
    letter-spacing: -1.44px;

    span {
      color: ${calloutColor};
    }
  }

  p {
    font-size: 2rem;
    padding: 0 2rem;
    line-height: 3rem;

    span:after {
      content: "";
    }
  }

  ${ButtonLink} {
    text-decoration: underline;
    color: #fff;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  ${mobileMediaQuery} {
    h1 {
      font-size: 3rem;
      margin-top: 4rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
`;

const OverlayContentWrapper = styled.div`
  max-width: 56rem;
  overflow: auto;
`;

const OverlayButtons = styled.div`
  margin: 4.8rem 0 2.4rem;

  button {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:not(:disabled):hover {
      background-color: #fff;
      border-color: #fff;
      color: #000;
    }
  }

  button:first-child {
    background: ${calloutColor};
    border-color: ${calloutColor};
    color: #000;
  }

  ${mobileMediaQuery} {
    button {
      width: 100%;
    }

    && button + button {
      margin: 0.8rem 0 0;
    }
  }
`;

const CreateCredentialsPromptOverlay = ({
  setShow,
  setShowConfirmDecline,
  accountsHost,
  authProvider,
}: Pick<React.ComponentProps<typeof RecoveryCredentialsOverlay>,
  'saveUserMetadata' | 'setShow' | 'accountsHost' | 'authProvider'> & {
    setShowConfirmDecline: (value: boolean) => void;
  }) => {
  const { openPopup } = useCreateCredentialsPopup({
    accountsHost,
    authProvider,
    onComplete: () => setShow(false)
  });

  return <StyledOverlay onClose={() => setShowConfirmDecline(true)} show={true}>
    <OverlayContentWrapper>
      <h1>Save your <span>highlights and notes</span> with OpenStax</h1>
      <p>
        Create an OpenStax account or log in to retain access to your highlights
        and notes even after your course ends.<br/>
        It's completely free!
      </p>
      <OverlayButtons>
        <Button onClick={openPopup}>Sign up</Button>
      </OverlayButtons>
      <ButtonLink onClick={() => setShowConfirmDecline(true)}>I don’t want to save my highlights and notes</ButtonLink>
    </OverlayContentWrapper>
  </StyledOverlay>;
};

export const RecoveryCredentialsOverlay = ({ setShow, accountsHost, authProvider, saveUserMetadata }: {
  setShow: (value: boolean) => void;
  accountsHost: string;
  authProvider: BrowserServices['authProvider'];
  saveUserMetadata: (payload: UserMetadataPayload) => void;
}) => {
  const [showConfirmDecline, setShowConfirmDecline] = React.useState(false);
  if (showConfirmDecline) {
    return <ConfirmDeclineRecoveryCredentials
      setShowConfirm={setShowConfirmDecline}
      saveUserMetadata={saveUserMetadata}
      setShow={setShow}
    />;
  }

  return <CreateCredentialsPromptOverlay
    setShow={setShow}
    accountsHost={accountsHost}
    authProvider={authProvider}
    saveUserMetadata={saveUserMetadata}
    setShowConfirmDecline={setShowConfirmDecline}
  />;
};
