import styled from 'styled-components';
import * as UI from '@openstax/ui-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
  background-color: #f9f9f9;
`;


const ActivationPage = (props: { handleRegisterInstructor: () => void }) => {
  return (
    <Container>
      <UI.H2>
        This course is not yet active.
      </UI.H2>
      <UI.Paragraph>Click the button below to activate this course and add yourself as an instructor.</UI.Paragraph>
      <UI.Button onClick={props.handleRegisterInstructor}>Activate Course</UI.Button>
    </Container>
  );
};

export {ActivationPage};
