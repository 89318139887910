import React from 'react';
import styled from 'styled-components';
import { createRoute, makeScreen } from "../../core/services";
import {
  fetchIdle,
  fetchLoading,
  FetchState,
  FetchStateType,
  fetchSuccess,
  stateHasData,
} from "@openstax/ts-utils/fetch";
import { AnyOrnResource } from "@openstax/orn-locator";
import { SelectScopeContent } from "../components/SelectScopeContent";
import { useOrn, useOrns } from "../utils/orn-utils";
import * as UI from '@openstax/ui-components';
import { useApiClient } from '../../api';
import {
  AssignmentCreateResponse,
  AssignmentReadResponse,
  CreateAssignmentStepPayload
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import { ResourceGrid } from "../components/ResourceGrid";
import { useFrontendConfigValue } from "../../configProvider/use";
import { renderRouteUrl } from "../../core";
import { launchScreen } from "../../student/screens/Launch";
import { selectActivity } from "../utils/select-activity";
import { useContextMetadata } from '../utils/context-metadata';
import { AssignmentOptions } from '../hooks/options';
import { Auth } from "../components/Auth";
import { reviewSubmissionScreen, ReviewSubmissionInterface } from "./ReviewSubmissions";
import { useLaunchTokenData } from "../../auth/useTokenData";
import { useGetAssignmentByResource } from '../hooks/resourceLink';
import { ToastProvider, Toasts } from "../../toasts/ToastContext";
import { useAssignmentManagementStates } from "../hooks/grades";
import { useUserRoles } from "../../auth/useAuth";
import { BodyPortalSlotsContext } from '@openstax/ui-components';
import useHelpMenu from '../../components/HelpMenu';
import { useInitializePI } from '../hooks/InitializePI';
import caretUpIcon from '../../assets/icons/caret-up.svg';
import caretDownIcon from '../../assets/icons/caret-down.svg';
import { ActivationPage } from "../components/ActivationScreen";
import { useServices } from '../../core/context/services';
import { LtiImage } from '@openstax/lti';
import { assertDefined } from '@openstax/ts-utils/assertions';

const Container = styled.div`
  max-width: 62.4rem;
  padding: 0 1.6rem 5.2rem;
  margin: 5.2rem auto;
  color: ${UI.colors.palette.neutralDarker};
  `;

const Heading = styled.h1`
  margin: 0 0 2.7rem;
`;

const SubHeading = styled.h2`
  line-height: 2rem;
  font-size: 1.6rem;
  margin: 0 0 0.2rem;
`;

const TextSection = styled.p`
  line-height: 2rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  color: ${UI.colors.palette.neutralThin};

  button {
    font-size: 1.6rem;
  }
`;

const StyledToggleButtonGroup = styled(UI.ToggleButtonGroup)`
  margin: 2rem 0;
  max-width: calc(100vw - 3.2rem);
`;

const StyledResourceGrid = styled(ResourceGrid)`
  margin: 2.8rem 0;
`;

const ToggleAllBooksLink = styled(UI.ButtonLink)`
  font-size: 1.4rem;
  font-weight: bold;
  align-items: baseline;
  display: flex;
  gap: 0.8rem;
  margin: 4rem auto;
`;

const AllBooksLoader = styled(UI.Loader)`
  height: 19.2rem;
`;

type Activity = {
  id: string;
  title?: string;
  description?: string;
  activities: Array<{
    icon?: LtiImage;
  }>;
}

// Weirdness: renderRouteUrl expects the second argument to be of type undefined
/* eslint-disable @typescript-eslint/no-explicit-any */
const formatActivity = (activity: Activity) => ({
  item: {
    title: activity.title || '',
    text: activity.description,
    icon: activity.activities.length === 1 ? activity.activities[0].icon : undefined,
    url: new URL(renderRouteUrl(launchScreen, {id: activity.id} as any), window.location.href).href,
    lineItem: { scoreMaximum: 100 }
  },
  editUrl: new URL(renderRouteUrl(editActivityScreen, {id: activity.id} as any), window.location.href).href,

  // this is not used by lti-gateway, but is included because it can be helpful for local dev / debugging
  reviewUrl: new URL(
    renderRouteUrl(reviewSubmissionScreen, {assignmentId: activity.id} as any),
    window.location.href
  ).href,
});
/* eslint-disable @typescript-eslint/no-explicit-any */


const useCreateAssignment = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const { launchToken } = useServices();
  const [state, setState] = React.useState<FetchState<AssignmentCreateResponse, string>>(fetchIdle());

  const save = React.useCallback((
    title: string,
    scope: string,
    activities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions,
    contextId?: string,
  ) => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0CreateAssignment({
      payload: {
        title,
        scope,
        activities,
        customOrder,
        options,
        ...(contextId ? { contextId } : {}),
      },
      query: { t: launchToken }
    })
      .then(response => response.acceptStatus(201))
      .then(response => response.load())
      .then(response => {
        setState(fetchSuccess(response));

        selectActivity(formatActivity(response));
      })
      .catch(setAppError)
    ;
  }, [apiClient, setAppError, launchToken]);

  return [state, save] as const;
};

const useEditAssignment = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<AssignmentCreateResponse, string>>(fetchIdle());

  const save = React.useCallback((
    id: string,
    activities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions
  ) => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0WriteAssignment({
      params: {id},
      payload: {activities, customOrder, options}
    })
      .then(response => response.acceptStatus(201))
      .then(response => response.load())
      .then(response => {
        setState(fetchSuccess(response));

        selectActivity(formatActivity(response));
      })
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return [state, save] as const;
};

const SelectScope = (params: {setScope: (orn: AnyOrnResource) => void; usedScopes: string[]}) => {
  const featuredScopes = useFrontendConfigValue('featuredScopes');
  const [allBooksClicked, setAllBooksClicked] = React.useState<boolean>(false);
  const featuredScopeOrns = React.useMemo(() => stateHasData(featuredScopes)
    ? featuredScopes.data?.split(',').filter(orn => !!orn)
    : undefined
  , [featuredScopes]);
  const featuredState = useOrns(featuredScopeOrns);
  const launchTokenData = useLaunchTokenData();
  const allBooks = React.useMemo(() =>
    allBooksClicked || (stateHasData(featuredState) && featuredState.data.length < 1)
  , [allBooksClicked, featuredState]);
  const [HelpMenu, ContactFormIframe] = useHelpMenu();
  const toggleAllBooksRef = React.useRef<HTMLButtonElement>(null);

  return <>
    {stateHasData(featuredState)
      ? <>
        {launchTokenData.branding !== false ?
          <>
            <UI.NavBar logo={true}><HelpMenu /></UI.NavBar>
            <ContactFormIframe />
          </>
          : null}
        <Container>
          <Toasts />
          <Heading>Select book</Heading>
          <SubHeading>Titles with assignable readings, questions, and activities</SubHeading>
          <TextSection>
            Build customized assignments and keep students engaged with a wide range of question types.
          </TextSection>
          <StyledResourceGrid
            resources={featuredState.data}
            onSelect={params.setScope}
          />

          <ToggleAllBooksLink
            onClick={() => setAllBooksClicked(prev => !prev)}
            aria-expanded={allBooks}
            ref={toggleAllBooksRef}
          >
            {allBooks ? 'Hide' : 'Show'} all books
            <img src={allBooks ? caretUpIcon : caretDownIcon} alt="" />
          </ToggleAllBooksLink>

          {allBooks
            ? <>
              <SubHeading>Titles with assignable readings</SubHeading>
                <TextSection>
                  Assign readings from the OpenStax textbook. Custom assignments and
                  additional content will be available in the future for these titles.
                </TextSection>
              <SelectAnyScope setScope={params.setScope} scrollToRef={toggleAllBooksRef} />
            </>
            : null
          }
        </Container>
      </>
      : <UI.Loader />}
  </>;
};

const dateFormat = () =>
  new Date().toLocaleString(undefined, {dateStyle: 'short', timeStyle: 'short'});

const library = 'https://openstax.org/orn/library/en';
const scopeParents = [library];

const ResourceGridGroup = styled(({
  heading,
  className,
  resources,
  onSelect
}: {
  heading: string;
  className?: string;
  resources: AnyOrnResource[];
  onSelect: (orn: AnyOrnResource) => void;
}) => {
  return (
    <div className={className}>
      <UI.H3>{heading}</UI.H3>
      <StyledResourceGrid resources={resources} onSelect={onSelect} />
    </div>
  );
})``;

const SelectAnyScope = (
  params: {
    setScope: (orn: AnyOrnResource) => void;
    scrollToRef: React.MutableRefObject<HTMLElement | null>;
  }
) => {
  const libraryState = useOrn(library);
  const [visibleGroup, setVisibleGroup] = React.useState<Set<UI.Key>>(new Set(['All']));

  React.useEffect(() => {
    setTimeout(() => {
      if (stateHasData(libraryState) && params.scrollToRef && params.scrollToRef.current) {
        const offset = params.scrollToRef.current.getBoundingClientRect().top + window.scrollY - 24;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    }, 100);
  }, [libraryState, params.scrollToRef]);


  const groups = React.useMemo(() => {
    if (!stateHasData(libraryState) || !('contents' in libraryState.data)) return [];

    const contents: AnyOrnResource[] = libraryState.data.contents as AnyOrnResource[];

    const grouped = contents.reduce<Record<string, AnyOrnResource[]>>((acc, item) => {
      const subject = 'categories' in item ? item.categories[0].subject_name : '';
      if (!acc[subject]) { acc[subject] = []; }
      acc[subject].push(item);
      return acc;
    }, {});

    return Object.entries(
      Object.fromEntries(Object.entries(grouped).sort(([a], [b]) => a.localeCompare(b)))
    );
  }, [libraryState]);

  return (stateHasData(libraryState) ? <>
    {groups.every(([name]) => name) ?
      <StyledToggleButtonGroup
        selectedItems={visibleGroup}
        onSelectionChange={setVisibleGroup}
      >
        {['All', ...groups.map(([name]) => name)].map(name => ({ key: name, value: name }))}
      </StyledToggleButtonGroup>
    : null}
    {
      (visibleGroup.has('All') ? groups :
        groups.filter(([name]) => visibleGroup.has(name))).map(([name, resources]) => (
        <ResourceGridGroup
          key={name}
          heading={name}
          resources={resources}
          onSelect={params.setScope}
        />
      ))
    }
    <p>Advanced Placement® and AP® are trademarks registered and/or owned by the College Board,
      which is not affiliated with, and does not endorse, this site.</p>
  </> : <AllBooksLoader />);
};

const SelectActivity = () => {
  const [scope, setScope] = React.useState<AnyOrnResource | null>();
  const [createState, createAssignment] = useCreateAssignment();
  const launchTokenData = useLaunchTokenData();
  const [assignmentTitle, setAssignmentTitle] = React.useState(
    launchTokenData?.title || (
      launchTokenData?.platformProductFamilyCode === 'canvas' &&
      launchTokenData.canvasPlacement !== 'link_selection' ? '' : `New Assignment ${dateFormat()}`
    )
  );
  const contextId = typeof launchTokenData.contextId === 'string' ? launchTokenData.contextId : undefined;
  const [contextMetadata, saveContextMetadata, registerInstructor] = useContextMetadata(contextId);
  const contextMetadataOrn = useOrn(stateHasData(contextMetadata) ? contextMetadata.data.lastScope : undefined);
  const usedScopes = stateHasData(contextMetadata) ? contextMetadata.data.usedScopes : [];

  React.useEffect(() => {
    if (scope === undefined && stateHasData(contextMetadata) && !contextMetadata.data.lastScope) {
      setScope(null);
    }
  }, [contextMetadata, scope]);

  React.useEffect(() => {
    if (!contextId && scope === undefined) {
      setScope(null);
    }
  }, [contextId, scope]);

  React.useEffect(() => {
    if (stateHasData(contextMetadataOrn) && scope === undefined) {
      setScope(contextMetadataOrn.data);
    }
  }, [contextMetadataOrn, scope]);

  const onCreateSave = (scope: AnyOrnResource)  => (
    flatActivities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions
  ) => {
    createAssignment(assignmentTitle, scope.orn, flatActivities, customOrder, options, contextId);

    if (stateHasData(contextMetadata)) {
      const lastScope = scope.orn;
      if (!usedScopes.includes(lastScope)) { usedScopes.push(lastScope); }

      saveContextMetadata({
        lastScope,
        usedScopes,
      });
    }
  };

  if ((stateHasData(contextMetadata) && (!contextMetadata.data.instructors
    || contextMetadata.data.instructors.length === 0))) {
    return (
      <ActivationPage handleRegisterInstructor={registerInstructor}/>
    );
  }

  return scope === undefined ? <UI.Loader/> :
    scope === null
      ? <SelectScope setScope={setScope} usedScopes={usedScopes}/>
      : <SelectScopeContent
          onUpdateTitle={setAssignmentTitle}
          assignmentTitle={assignmentTitle}
          parents={scopeParents}
          scopeData={scope}
          scope={scope.orn}
          back={() => setScope(null)}
          onSave={onCreateSave(scope)}
          loading={createState.type === FetchStateType.LOADING}
        />;
};


const EditActivity = ({id}: {id?: string} & JSX.IntrinsicAttributes) => {
  const assignmentState = useGetAssignmentByResource(assertDefined(id, 'Missing assignment ID'));
  const launchTokenData = useLaunchTokenData();
  const assignmentManagementStates = useAssignmentManagementStates(stateHasData(assignmentState)
    ? assignmentState.data?.id
    : undefined
  );
  const [editState, editAssignment] = useEditAssignment();
  const roles = useUserRoles();
  const [screen, setScreen] = React.useState<'submissions' | 'edit' | undefined>();
  const contextId = typeof launchTokenData.contextId === 'string' ? launchTokenData.contextId : undefined;
  const [contextMetadata, , registerInstructor] = useContextMetadata(contextId);

  const submissionReviewBetaMember = React.useMemo(() =>
    stateHasData(roles) ? roles.data.includes('submission-review-beta') : undefined
  , [roles]);

  const onEditSave = (assignmentData: AssignmentReadResponse)  => (
    flatActivities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions
    ) => {
      editAssignment(assignmentData.id, flatActivities, customOrder, options);
  };

  if ((stateHasData(contextMetadata) && (!contextMetadata.data.instructors
    || contextMetadata.data.instructors.length === 0))) {
    return (
      <ActivationPage handleRegisterInstructor={registerInstructor}/>
    );
  }

  return stateHasData(assignmentState) && submissionReviewBetaMember !== undefined
    ? submissionReviewBetaMember && ['submissions', undefined].includes(screen)
      ? <ReviewSubmissionInterface
          assignment={assignmentState.data}
          assignmentManagementStates={assignmentManagementStates}
          onEditAssignment={() => setScreen('edit')}
      />
      : <SelectScopeContent
        assignmentData={assignmentState.data}
        assignmentTitle={launchTokenData?.title || assignmentState.data.title}
        onShowSubmissions={submissionReviewBetaMember ? () => setScreen('submissions') : undefined}
        assignmentManagementStates={assignmentManagementStates}
        parents={scopeParents}
        scope={assignmentState.data.scope}
        onSave={onEditSave(assignmentState.data)}
        loading={editState.type === FetchStateType.LOADING}
        editView
        showEditToast={editState.type === FetchStateType.SUCCESS}
        isForkedCopy={id !== assignmentState.data.id}
      />
    : <UI.Loader />;
};

const ScreenWrapper = ({ children }: React.PropsWithChildren<unknown>) => {
  useInitializePI();

  return (
    <ToastProvider>
        <Auth>
          <BodyPortalSlotsContext.Provider value={['nav', 'root']}>
            {children}
          </BodyPortalSlotsContext.Provider>
        </Auth>
    </ToastProvider>
  );
};

export const selectActivityScreen = createRoute({name: 'SelectActivity', path: '/instructor/select-activity',
  handler: makeScreen(SelectActivity, ScreenWrapper)
});

export const editActivityScreen = createRoute({name: 'EditActivity', path: '/instructor/edit/:id',
  handler: makeScreen(EditActivity, ScreenWrapper)
});
