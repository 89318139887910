import {
  ContextMetadataPayload,
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/contextMetadata';
import {
  FetchState,
  fetchIdle,
  fetchLoading,
  fetchSuccess,
} from '@openstax/ts-utils/fetch';
import React from 'react';
import { useApiClient } from '../../api';
import { useSetAppError } from '@openstax/ui-components';
import { useServices } from '../../core/context/services';

export const useContextMetadata = (contextId?: string) => {
  const apiClient = useApiClient();
  const services = useServices()
  type ResponsePromise = ReturnType<
    typeof apiClient.apiV0GetContextMetadata | typeof apiClient.apiV0WriteContextMetadata
  >;

  const [state, setState] = React.useState<FetchState<ContextMetadataPayload, string>>(
    contextId ? fetchLoading() : fetchIdle()
  );

  const setAppError = useSetAppError();

  const setStateFromResponse = React.useCallback((responsePromise: ResponsePromise) => {
    responsePromise.then((response) => response.acceptStatus(200))
                   .then((response) => response.load())
                   .then((response) => setState(fetchSuccess(response)))
                   .catch(setAppError)
    ;
  }, [setAppError]);

  React.useEffect(() => {
    if (contextId) {
      setStateFromResponse(apiClient.apiV0GetContextMetadata({params: { contextId }}));
    }
  }, [apiClient, contextId, setStateFromResponse]);

  const save = React.useCallback((payload: ContextMetadataPayload) => {
    if (contextId) {
      setState((previous) => fetchLoading(previous));

      setStateFromResponse(apiClient.apiV0WriteContextMetadata({ params: { contextId }, payload }));
    }
  }, [apiClient, contextId, setStateFromResponse]);

  const registerInstructor = React.useCallback(() => {
    
    const launchToken = services.launchToken
    setState((previous) => fetchLoading(previous));
    setStateFromResponse(apiClient.apiV0AddInstructorToContextMetadata({query: {t: launchToken } }));

  }, [apiClient, services, setStateFromResponse]);

  return [state, save, registerInstructor] as const;
};
