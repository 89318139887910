import React from 'react';
import {
  AssignmentReadResponse, CreateAssignmentStepPayload
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import { stateHasData } from "@openstax/ts-utils/fetch";
import { AnyOrnResource } from "@openstax/orn-locator";
import * as UI from '@openstax/ui-components';
import {
  GlobalStyle, Main,
  MainContent, ModalBody, ModalButtons
} from './styles';
import { SupportResourceSelect } from "../SupportResourceSelect";
import { useOrn } from "../../utils/orn-utils";
import { ContentActivitySelect } from "../ContentActivitySelect";
import { ContentPreview } from '../ContentPreview';
import Warning from '../../../components/Warning';
import Breadcrumbs from '../Breadcrumbs';
import { FlattenedActivityStep, stepIsRemoved } from "../../utils/activity-step-utils";
import { AssignmentOptions, useAssignmentOptions } from '../../hooks/options';
import styled from 'styled-components';
import EditableHeading from '../EditableHeading';
import { setContentMetadata } from "../../../utils/dataLayer";
import { useLaunchTokenData } from "../../../auth/useTokenData";
import { usePushToast, Toasts } from "../../../toasts/ToastContext";
import { AssignmentManagementStates } from "../../hooks/grades";
import useMatchMobileQuery from '../../../utils/reactUtils';
import { BodyPortalSlotsContext } from '@openstax/ui-components';
import useHelpMenu from '../../../components/HelpMenu';
import { useInitializePI } from '../../hooks/InitializePI';
import { SubScopeItem } from '../../types';
import Sidebar from './Sidebar';
import ActionBar from './ActionBar';
import useSubScopes from './useSubScopes';
import useSubScopeCounts from './useSubScopeCounts';
import useUpdateActivities from './useUpdateActivities';
import useWarningMessages from './useWarningMessages';

export type ActivityUpdatePayload = {
  selected?: FlattenedActivityStep[];
  unselected?: FlattenedActivityStep[];
};
export type ActivityUpdateHandler = (
  doChange: ActivityUpdatePayload | ((existing: FlattenedActivityStep[]) => ActivityUpdatePayload)
) => void;

const PickerContainer = styled.div`
  border-top: 1px solid ${UI.colors.palette.pale};
  padding-top: 1.6rem;
`;

const BaseSelectScopeContent = ({
  showPreview,
  setShowPreview,
  navBarIsVisible,
  subScope,
  setSubScope,
  ...props
}: React.ComponentProps<typeof SelectScopeContent> & {
  showPreview: boolean; setShowPreview: React.Dispatch<boolean>;
  navBarIsVisible: boolean;
  subScope: 'support' | SubScopeItem;
  setSubScope: React.Dispatch<'support' | SubScopeItem>;
}) => {
  const assignmentData = 'assignmentData' in props ? props.assignmentData : undefined;

  // TODO - if activitiesByOrn is set on props.data, then we are editing an old
  // assignment, we should process activitiesByOrn into a new flatActivities that
  // includes the attachedTo and stepId fields
  //
  // in this case its ok to discard the existing flatActivities because old assignments
  // can't have any important metadata on the flatActivities yet
  const [flatActivities, setFlatActivities] = React.useState<FlattenedActivityStep[]>(
    assignmentData?.activities ?? []
  );
  const scope = useOrn(props.scope);
  const launchTokenData = useLaunchTokenData();
  const subScopes = useSubScopes(props.scope);
  const assignmentOptions = useAssignmentOptions(assignmentData, flatActivities.filter(step => !stepIsRemoved(step)));
  const [showWarning, setShowWarning] = React.useState<boolean>(false);
  const pushToast = usePushToast();

  React.useEffect(() => {
    if (stateHasData(scope) && subScope !== 'support') {
      setContentMetadata(subScope.subScope, scope.data);
    } else if (stateHasData(scope)) {
      // there is no content selected but we want to record the scope, so
      // use the scope as the content. can clean up when contentId is not required
      setContentMetadata(scope.data, scope.data);
    }
  }, [subScope, scope]);

  const isLegacyAssignmentInCopiedCourse = React.useMemo(() => !!launchTokenData.contextId
    && assignmentData !== undefined
    && (launchTokenData.contextId !== assignmentData.contextId)
    && !('resourceRegistration' in assignmentData),
  [launchTokenData, assignmentData]);

  const [hasCustomOrder, setHasCustomOrder] = React.useState(assignmentData?.customOrder || false);

  React.useEffect(() => {
    // if activities are cleared, reset customOrder flag
    if (flatActivities.length === 0) {
      setHasCustomOrder(false);
      setShowPreview(false);
    }
  }, [flatActivities, setShowPreview]);

  // legacy assignments in copied courses cannot be modified so the select screen is not rendered
  React.useEffect(() =>
    setShowPreview(isLegacyAssignmentInCopiedCourse), [isLegacyAssignmentInCopiedCourse, setShowPreview]
  );

  const {subScopeCounts, setFirstSelectedResource} = useSubScopeCounts({
    flatActivities,
    subScopes,
    setSubScope
  });

  const updateActivities = useUpdateActivities({
    hasCustomOrder,
    scope,
    setFlatActivities
  });

  const supportScopes = React.useMemo(() => [
    props.scope,
    ...props.parents
  ], [props.scope, props.parents]);

  React.useEffect(() => {
    if (props.showEditToast) {
      pushToast({
        title: 'Success',
        message: 'Your changes have been saved',
        variant: 'success',
        dismissAfterMs: 5000
      });
    }
  }, [props.showEditToast, pushToast]);

  const isMobile = useMatchMobileQuery();

  const warningMessages = useWarningMessages({
    editView: props.editView,
    assignmentManagementStates: props.assignmentManagementStates,
    isForkedCopy: props.isForkedCopy,
    assignmentData,
    isLegacyAssignmentInCopiedCourse
  });

  return <>
    <UI.Modal show={showWarning} heading={'Leave without saving?'} onModalClose={() => setShowWarning(false)}>
      <ModalBody className='inner'>
        {'Are you sure you want to exit this page? Your changes will not be saved.'}
        <ModalButtons>
          <UI.Button variant="light" onClick={() => setShowWarning(false)}>Stay</UI.Button>
          <UI.Button onClick={props.back}>Leave anyway</UI.Button>
        </ModalButtons>
      </ModalBody>
    </UI.Modal>
    <Toasts />
    {!showPreview
      ? <Sidebar
        subScope={subScope}
        setSubScope={setSubScope}
        subScopeCounts={subScopeCounts}
        editView={props.editView}
        navBarIsVisible={navBarIsVisible}
        assignmentManagementStates={props.assignmentManagementStates}
        subScopes={subScopes}
        />
      : null}
    <MainContent aria-live="polite" navMobile={isMobile} navVisible={!showPreview}>
      {!props.editView && stateHasData(scope)
        ? <Breadcrumbs
          warningHandler={() => setShowWarning(true)}
          items={[{ text: 'title' in scope.data ? scope.data.title : '' },]}
        />
        : null}
      {warningMessages.length ? <Warning messages={warningMessages} /> : null}
      {subScope === 'support' || (!props.assignmentTitle &&
                                  launchTokenData?.platformProductFamilyCode === 'canvas' &&
                                  launchTokenData.canvasPlacement !== 'link_selection')
        ? null
        : <EditableHeading
          title={props.assignmentTitle || ''}
          handleEdit={props.onUpdateTitle}
          loading={!!props.loading}
        />
      }
      {subScope === 'support'
        ? <SupportResourceSelect scopes={supportScopes} />
        : <PickerContainer>
          {showPreview
            ? <ContentPreview
              assignmentManagementStates={props.assignmentManagementStates}
              defaultFlatActivities={flatActivities.filter(step => !stepIsRemoved(step))}
              assignmentOptions={assignmentOptions}
              setFlatActivities={setFlatActivities}
              setHasCustomOrder={setHasCustomOrder}
              editingDisabled={isLegacyAssignmentInCopiedCourse}
            />
            : <ContentActivitySelect
              scope={subScope.subScope}
              selectedContent={subScope.contents}
              selectedActivities={flatActivities}
              setFlatActivities={setFlatActivities}
              onChange={updateActivities}
            />
          }
        </PickerContainer>
      }
    </MainContent>
    <ActionBar
      onShowSubmissions={props.onShowSubmissions}
      subScope={subScope}
      showPreview={showPreview}
      isLegacyAssignmentInCopiedCourse={isLegacyAssignmentInCopiedCourse}
      id={assignmentData?.id}
      setShowPreview={setShowPreview}
      setFirstSelectedResource={setFirstSelectedResource}
      editView={props.editView}
      onSave={props.onSave}
      loading={props.loading}
      flatActivities={flatActivities}
      setFlatActivities={setFlatActivities}
      assignmentOptions={assignmentOptions.assignmentOptions}
      hasCustomOrder={hasCustomOrder}
    />
  </>;
};

export const SelectScopeContent = (props: {
  scope: string;
  parents: string[];
  onSave: (
    flatActivities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions,
  ) => void;
  back?: () => void;
  loading?: boolean;
  editView?: boolean;
  showEditToast?: boolean;
  onUpdateTitle?: (title: string) => void;
  assignmentTitle?: string;
  assignmentManagementStates?: AssignmentManagementStates;
  onShowSubmissions?: () => void;
  isForkedCopy?: boolean;
} & (
    { scopeData: AnyOrnResource } |
    { assignmentData: AssignmentReadResponse }
  )) => {

  useInitializePI();
  const launchTokenData = useLaunchTokenData();
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const showNavBar = launchTokenData.branding !== false;
  const id = 'assignmentData' in props ? props.assignmentData.id : undefined;
  const [subScope, setSubScope] = React.useState<'support' | SubScopeItem>('support');
  const [HelpMenu, ContactFormIframe] = useHelpMenu([{
    label: 'Resources', callback: () => {
      setShowPreview(false);
      setSubScope('support');
    }
  }]);

  return <BodyPortalSlotsContext.Provider value={[
    'nav',
    'sidebar',
    'root'
  ]}>
    <GlobalStyle />
    {showNavBar ? <UI.NavBar logo={true}><HelpMenu id={id} /></UI.NavBar> : null}
    <ContactFormIframe />
    <Main navVisible={!showPreview}>
      <BaseSelectScopeContent
        {...props}
        navBarIsVisible={showNavBar}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
        subScope={subScope}
        setSubScope={setSubScope}
      />
    </Main>
  </BodyPortalSlotsContext.Provider>;
};
