import React from 'react';
import { AnyOrnResource } from "@openstax/orn-locator";
import styled from 'styled-components';
import { colors } from "@openstax/ui-components";
import { TooltipGroup } from "@openstax/ui-components";

const gridImageSize = 4.8;

const NoImage = styled.div`
  height: ${gridImageSize}rem;
  width: ${gridImageSize}rem;
  background-color: ${colors.palette.neutralThin};
`;

const Grid = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  row-gap: 2.4rem;
  column-gap: 3.2rem;
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));

  li {
    &, button {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: flex-start;
    }

    img {
      height: ${gridImageSize}rem;
      width: ${gridImageSize}rem;
    }

    .title {
      font-size: 1.6rem;
      text-align: left;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-right: 0.8rem;
    }
  }

  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    flex-grow: 1;
    border: 0.1rem solid ${colors.palette.pale};
    gap: 1.1rem;
    position: relative;

    img {
      margin: 0.8rem 0 0.8rem 0.8rem;
    }
  }
`;

const TitleTooltip = styled(TooltipGroup)`
  margin: 0.4rem;
  white-space: break-spaces;
  text-align: left;
`;

const checkClamp = (element: HTMLDivElement | null): boolean => {
  if (!element) return false;
  return element.scrollHeight > element.clientHeight;
};

const ResourceGridButton = ({
  resource,
  onSelect
}: {
  resource: AnyOrnResource & { title: string };
  onSelect: (resource: AnyOrnResource) => void;
}) => {
  const titleRef = React.useRef<HTMLDivElement>(null);
  const [isClamped, setIsClamped] = React.useState(false);

  React.useEffect(() => {
    const updateClamp = () => {
      if (titleRef.current) {
        setIsClamped(checkClamp(titleRef.current));
      }
    };

    updateClamp();
    window.addEventListener('resize', updateClamp);

    return () => {
      window.removeEventListener('resize', updateClamp);
    };
  }, [resource.title, titleRef]);

  return <button
    onClick={() => onSelect(resource)}
  >
    {'images' in resource && 'square' in resource.images
      ? <img src={resource.images.square} alt="" />
      : <NoImage />
    }
    <div className="title" ref={titleRef}>
      {resource.title}
    </div>
    {isClamped ? <TitleTooltip placement="bottom">
      {resource.title}
    </TitleTooltip> : null}
  </button>;
};

export const ResourceGrid = (
  {
    resources,
    onSelect,
    ...rest
  }: {
    resources: AnyOrnResource[];
    onSelect: (resource: AnyOrnResource) => void;
  }
) => {
  return <Grid {...rest}>
    {resources.map((resource, index) => <React.Fragment key={index}>
      {'id' in resource && 'title' in resource ?
        <li>
          <ResourceGridButton resource={resource} onSelect={onSelect} />
        </li>
        : null}</React.Fragment>)}
  </Grid>;
};
