import { Failure } from '@openstax/lti';
import uniq from 'lodash/fp/uniq';

export class CsvDownloadError extends Error {
  static readonly TYPE = 'CsvDownloadError';
  constructor(cause: Error) {
    super('Download unsuccessful', { cause });
  }
}

export class NoScoresError extends Error {
  static readonly TYPE = 'NoScoresError';

  constructor() {
    super('No scores available');
  }
}

export class ScoresSyncError extends Error {
  static readonly TYPE = 'ScoresSyncError';
  failures: Failure[];

  constructor(failures: Failure[]) {
    const uniqueMessages = uniq(failures.map((failure) => failure.message));
    super(uniqueMessages.length === 1 ? uniqueMessages[0] : `${uniqueMessages.length} errors encountered`);
    this.failures = failures;
  }
}

export class StudentsNotInCourse extends Error {
  static readonly TYPE = 'StudentsNotInCourse';
  failures: Failure[];

  constructor(failures: Failure[]) {
    super('Student not in course');
    this.failures = failures;
  }
}

export class UpdateLimitReached extends Error {
  static readonly TYPE = 'UpdateLimitReached';
  failures: Failure[];

  constructor(failures: Failure[]) {
    super('Update limit reached');
    this.failures = failures;
  }
}

export class CourseNotActivatedForStudentError extends Error {
  static readonly TYPE = 'CourseNotActivatedForStudentError';
  constructor() {
    super('Activation error');
  }
}
