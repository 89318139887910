import { Checkbox, TooltipGroup } from "@openstax/ui-components";
import styled from "styled-components";
import { useAssignmentOptions } from "../hooks/options";
import get from 'lodash/fp/get';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    font-size: 1.6rem;
  }
`;

export const SyncPartialScores = ({
  assignmentOptions,
  handleChangeOptions,
}: {
  assignmentOptions: ReturnType<
    typeof useAssignmentOptions
  >["assignmentOptions"];
  handleChangeOptions: ReturnType<
    typeof useAssignmentOptions
  >["handleOptionChange"];
}) => {
  const value = get('assignments.options.sync_partial_scores', assignmentOptions);

  return (
    <CheckboxWrapper>
      <Checkbox
        onChange={() =>
          handleChangeOptions(!value, {
            integration: "assignments",
            option: "sync_partial_scores",
          })
        }
        checked={value === "true" || value === true}
      >
        Sync partial scores automatically
      </Checkbox>
      <TooltipGroup>
        All synced grades will be interpreted as final grades by the LMS.
      </TooltipGroup>
    </CheckboxWrapper>
  );
};
