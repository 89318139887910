import React from 'react';
import { StatefulAssignmentResponse } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student";
import { createRoute, makeScreen } from "../../core/services";
import { useApiClient } from "../../api";
import {
  FetchState,
  fetchError,
  fetchLoading,
  fetchSuccess,
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import { Assignment } from "../components/Assignment";
import * as UI from '@openstax/ui-components';
import { useServices } from "../../core/context/services";
import { Auth } from "../../instructor/components/Auth";
import { ToastProvider } from "../../toasts/ToastContext";
import { assertDefined } from '@openstax/ts-utils/assertions';
import { ActivationError } from '../../errors/ActivationError';

const useLaunchAssignment = (id: string) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const { launchToken } = useServices();
  const [state, setState] = React.useState<FetchState<StatefulAssignmentResponse, string>>(fetchLoading());

  const getAssignment = React.useCallback(async(options?: {skipActivationCheck: boolean}) => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0ReadAssignment({
      params: {id},
      payload: {launchToken},
      // TO-DO: remove query param when student activation error is no longer dismissible
      ...(options ? {query: {skipActivationCheck: options.skipActivationCheck.toString()}} : {})
    })
      .then(response => response.acceptStatus(200, 201))
      .then((response): Promise<StatefulAssignmentResponse | {error: string}>  => response.load())
      .then(response => {
        if ("error" in response && response.error === "CourseNotActivated") {
          setState(fetchError("CourseNotActivated"));
        } else if ("error" in response){
          throw new Error("Unknown error from apiV0ReadAssignment");
        } else {
          setState(fetchSuccess(response));
        }
      })
      .catch(setAppError);
  }, [apiClient, id, setAppError, launchToken]);

  return [state, getAssignment] as const;
};

const Launch = ({id}: {id?: string} & JSX.IntrinsicAttributes) => {
  const [showActivationError, setShowActivationError] = React.useState<boolean | undefined>(undefined);
  const [assignmentState, getAssignment] = useLaunchAssignment(assertDefined(id, 'Missing assignment ID'));

  React.useEffect(() => {
    getAssignment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (stateHasError(assignmentState) && assignmentState.error === 'CourseNotActivated') {
      setShowActivationError(true);
    } else {
      setShowActivationError(false);
    }
  }, [assignmentState]);

  return <ToastProvider>
    <Auth>
      {authState => stateHasError(assignmentState) && assignmentState.error !== 'CourseNotActivated'
        ? <UI.Error />
        :  stateHasData(authState) && showActivationError !== undefined
          ? (showActivationError
              ? <ActivationError dismiss={() => getAssignment({skipActivationCheck: true})} />
              : stateHasData(assignmentState)
                ? <Assignment assignment={assignmentState.data} user={authState.data} />
                : <UI.Loader />)
          : <UI.Loader />
      }
    </Auth>
  </ToastProvider>;
};

export const launchScreen = createRoute({name: 'LaunchScreen', path: '/launch/:id',
  handler: makeScreen(Launch)
});
