import styled, {createGlobalStyle, css} from 'styled-components';
import * as UI from '@openstax/ui-components';
import { visuallyHidden } from '../../../utils/styleUtils';
import { BodyPortal, BodyPortalSidebarNav, navDesktopHeight, SidebarNavStyles } from '@openstax/ui-components';
import { ButtonRow, LastSyncContainer } from '../Settings/styles';

export const Main = styled.div<{navVisible?: boolean}>`
  flex: 1;
`;

const actionBarHeight = '5.6rem';

const smallCountCss = css`
  background: ${UI.colors.palette.neutralThin};
  color: ${UI.colors.palette.white};
  border-radius: .4rem;
  min-width: 2.5rem;
  height: 1.5rem;
  margin: 0.3rem 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionBar = styled(BodyPortal)<{hide: boolean; flexEnd: boolean}>`
  background: #f4f4f4;
  transition-property: height padding;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  max-height: ${actionBarHeight};
  padding: 1.3rem 3rem;
  border-top: 1px solid #d5d5d5;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.flexEnd ? 'flex-end' : 'space-between'};
  align-items: center;

  button {
    font-size: 1.6rem;
    max-height: 3rem;
    margin-left: 1.6rem;

    &:first-child {
      margin-left: 0;
    }
  }

  ${(props) => !props.hide && css`
    @media (max-width: 34em) {
      padding: 1.3rem 0.8rem;

      button {
        padding: 0.8rem;

        & + button {
          margin-left: 0.4rem !important;
        }
      }
    }
  `}

  ${(props) => props.hide ? css`
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    * {
      display: none;
    }

  ` : ''}
`;

export const SubContent = styled.div`
  display: grid;
  align-content: start;

  ol {
    list-style: none;
    margin: 1.4rem 0 0;
    padding: 0;
    overflow: hidden auto;

    button {
      padding: 0.5rem 0.8rem 0.5rem 1.6rem;
      text-align: left;
      width: 100%;
      background: none;
      border: none;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2.0rem;
      color: ${UI.colors.palette.neutralDarker};
      cursor: pointer;
      display: flex;

      &:hover {
        background-color: #ddd;
      }
      &.selected {
        background-color: ${UI.colors.palette.mediumBlue};
        color: ${UI.colors.palette.white};
      }

      > span {
        display: flex;
        flex-grow: 1;
      }
      small {
        ${smallCountCss}
      }
      &.selected small {
        background: ${UI.colors.palette.white};
        color: ${UI.colors.palette.neutralDarker};
      }
    }

    // visually hide "Chapter" from titles
    .os-part-text {
      ${visuallyHidden}
    }
    .os-number {
      margin-right: 0.5rem;
    }

  }
`;

export const MainContent = styled.div<{navMobile?: boolean; navVisible?: boolean}>`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  ${props => props.navMobile && props.navVisible ? `margin-left: ${SidebarNavStyles.collapsedWidth}` : null}
`;

export const ModalBody = styled.div`
  padding: 3.2rem;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6rem;
`;

export const StyledSidebarNav = styled(BodyPortalSidebarNav)<{
  isMobile: boolean;
  actionBarIsVisible: boolean;
  navBarIsVisible: boolean;
}>`
  overflow: hidden;

  & ~ main::before, &.mobile ~ main::before {
    ${props => props.actionBarIsVisible ?
      `max-height: calc(100vh - ${navDesktopHeight}rem - ${actionBarHeight});` : null}
    top: ${navDesktopHeight}rem !important;
  }

  &.mobile {
    ${({ navBarIsVisible }) => navBarIsVisible && css`
      margin-top: ${navDesktopHeight}rem;
    `}

    ${({ actionBarIsVisible }) => actionBarIsVisible && css`
      height: calc(100vh - ${navDesktopHeight}rem - ${actionBarHeight});
    `}
  }

  ${LastSyncContainer} {
    margin: 0;
    padding: 0 0 1.6rem 1.6rem;
    text-align: left;
  }

  ${SidebarNavStyles.NavBody} header {
    padding-top: 5.8rem;

    h2 {
      font-size: 1.6rem;
      margin: 0;
      display: flex;
      color: ${UI.colors.palette.neutralDarker};

      button {
        margin: 0;
        border: none;
        background: none;
        padding: 1.6rem;
        flex: 1;
        display: flex;
        gap: 1rem;
        box-shadow: 0 0 0.4rem 0 #0000001A;
        min-height: 5.2rem;
        cursor: pointer;
        font: inherit;
        font-size: inherit;
        color: inherit;
        position: relative;
        text-align: left;

        &:hover {
          background-color: #ddd;
        }
      }

      small {
        ${smallCountCss}
        min-width: unset;
        width: 1.8rem;
        height: 1.8rem;
        margin: 0;
        padding: 0;
        background-color: ${UI.colors.palette.mediumBlue};
        color: ${UI.colors.palette.white};
        position: absolute;
        top: -0.4rem;
        right: 0.6rem;
        border-radius: 50%;
        border: 0.2rem solid ${UI.colors.palette.neutralBright};
        font-size: 0.8rem;
        font-weight: bold;
        line-height: 1.6rem;

        &.wide {
          width: 2.2rem;
          border-radius: 0.4rem;
        }
      }

      img {
        background: ${UI.colors.palette.neutralThin};
        width: 2rem;
        height: 2rem;
        padding: 0.3rem;
      }
    }

    ${ButtonRow} {
      padding: 1.6rem 1.2rem 1.6rem 1.6rem;
      width: auto;
      gap: 0.8rem;
      min-width: 22rem;
    }
  }

  &.collapsed {
    width: 5.2rem;

    ${SidebarNavStyles.NavBody} header {
      flex-direction: column;

      h2 {
        button {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.4rem;
          padding-left: 0;
          padding-right: 0;
          box-shadow: none;
        }
        span {
          display: block;
          font-size: 1.2rem;
        }
      }
    }
  }

  ${SidebarNavStyles.NavFooter} {
    transition: padding 200ms ease-in-out;
    &:not(:empty) {
      padding: 1.6rem;
    }
    button {
      width: 100%;
    }

    &.collapsed {
      padding: 0.8rem;

      .scope img {
        margin-right: 0;
        width: 4rem;
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  #root {
    display: flex;
    flex-direction: column;
    grid-area: main;
    overflow: hidden auto;
  }

  a {
    text-decoration: none;
    color: ${UI.colors.palette.mediumBlue};

    &:hover {
      text-decoration: underline;
      color: ${UI.colors.link.hover}
    }
  }

  body {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: min-content 1fr;
    grid-template-areas:
      "nav nav"
      "sidebar main"
      "footer footer";
    width: 100vw;
    height: 100vh;
  }

  [data-portal-slot="nav"] {
    grid-area: nav;
  }

  [data-portal-slot="sidebar"] {
    grid-area: sidebar;
  }

  [data-portal-slot="root"] {
    grid-area: main;
  }

  ${ActionBar} {
    grid-area: footer;
  }
`;
