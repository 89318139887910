import { createApiGateway } from '@openstax/ts-utils/services/apiGateway';
import { createUserRoleValidator } from '@openstax/ts-utils/services/authProvider/utils/userRoleValidator';
import { ErrorBoundary } from '@openstax/ui-components';
import { createBrowserHistory, Location } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { createAuthProvider } from './auth/authProvider';
import { frontendConfigProvider } from './configProvider';
import { getRequestResponder } from './core';
import { serviceProviderMiddleware } from './core/context/services';
import { composeResponseServiceMiddleware } from './core/services';
import { ErrorMessageModal } from './errors/modal';
import { MultipleFailureMessages, StudentsNotInCourseDetails } from './errors/sync';
import './index.css';
import { ActivationError } from './errors/ActivationError';

/*
 * the use of the service container pattern in an app that only
 * has one entry point is pretty academic. it might become relevant
 * if you had some external dependencies the FE accessed directly
 * that you wanted to use a fake driver for in dev (or something like that)
 */
const makeApiGateway = createApiGateway({fetch: fetch.bind(window)});
const configProvider = frontendConfigProvider(makeApiGateway);
const authProvider = createAuthProvider({window})(configProvider);
const history = createBrowserHistory();

const searchParams = new URLSearchParams(history.location.search);
const launchToken = searchParams.get('t') ?? undefined;
if (launchToken) {
  searchParams.delete('t');
  const search = searchParams.toString();
  history.replace(history.location.pathname + (search ? `?${search}` : ''));
}

const services = {
  authProvider,
  history,
  launchToken,
  roleValidator: createUserRoleValidator(authProvider, {application: () => configProvider.getValue('roleApplication')}),
  createApiGateway: makeApiGateway,
  configProvider,
};

export type BrowserServices = typeof services;

const handler = getRequestResponder(services, composeResponseServiceMiddleware(
  serviceProviderMiddleware,
));

const Router = () => {
  const [location, setLocation] = React.useState<Location>(services.history.location);

  React.useEffect(() => {
    return services.history.listen((locationChange) => {
      setLocation(locationChange.location);
    });
  }, []);

  return <>{handler(location)}</>;
};

const gradingHelpArticleSlug = 'Manually-Syncing-Scores-in-OpenStax-Assignable-beta-and-LMS-grading-policies';
const gradingHelpArticleUrl = `https://help.openstax.org/s/article/${gradingHelpArticleSlug}`;
const gradingHelpLink = <a href={gradingHelpArticleUrl} target="_blank" rel="noreferrer">Support Center</a>;

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      errorFallbacks={{
        CourseNotActivatedForStudentError: <ActivationError/>,
        CsvDownloadError: <><ErrorMessageModal>
        There was an issue downloading your scores. Please try again in a few minutes. If the issue persists, visit
        our <a href="https://openstax.secure.force.com/help" target="_blank" rel="noreferrer">Support Center</a>.
      </ErrorMessageModal><Router key='router'/></>,
        NoScoresError: <><ErrorMessageModal>
          No recorded scores were found for this assignment.
          Please make sure your students have started the assignment.
          If the issue persists, visit our {gradingHelpLink}.
        </ErrorMessageModal><Router key='router'/></>,
        ScoresSyncError: <><ErrorMessageModal>
          There was an issue syncing your scores.
          Please try again in a few minutes.
          If the issue persists, visit our {gradingHelpLink}.

          <MultipleFailureMessages/>

          <StudentsNotInCourseDetails/>
        </ErrorMessageModal><Router key='router'/></>,
        StudentsNotInCourse: <><ErrorMessageModal>
          One or more students are not enrolled in your course.
          Visit our {gradingHelpLink} if you need further assistance.

          <StudentsNotInCourseDetails/>
        </ErrorMessageModal><Router key='router'/></>,
        UpdateLimitReached: <><ErrorMessageModal>
          There was an issue syncing your scores.
          Your LMS has limited the number of times you can manually sync grades.
          Use the CSV export option or visit our {gradingHelpLink}.
        </ErrorMessageModal><Router key='router'/></>,
      }}
      renderFallback
      sentryDsn='https://8d362df880a448a090ab4e1a2d9b885e@o484761.ingest.sentry.io/4504951308222464'
    >
      <Router key='router'/>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
