import React from "react";
import kebabIcon from "../assets/icons/kebab.svg";
import { useServices } from "../core/context/services";
import { StatefulAssignmentResponse } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student";
import { styledMenu } from "./HelpMenu";

export default function KebabMenu({
  scope,
}: {
  scope: StatefulAssignmentResponse["scope"];
}) {
  const authProvider = useServices().authProvider;
  const browseHref = React.useMemo(
    () =>
      "urls" in scope && scope.urls && "images" in scope && scope.images
        ? authProvider.getAuthorizedLinkUrl(scope.urls.experience)
        : undefined,
    [scope, authProvider],
  );
  const mhHref = React.useMemo(() => {
    if (scope.type === "book" && scope.urls) {
      const url = new URL(scope.urls.experience);
      url.searchParams.set("modal", "MH");
      return authProvider.getAuthorizedLinkUrl(url.toString());
    } else {
      return undefined;
    }
  }, [scope, authProvider]);

  if (!mhHref && !browseHref) {
    return null;
  }

  return (
    <>
      <styledMenu.Button icon={kebabIcon} aria-label="Resources">
        {mhHref ? (
          <styledMenu.Item target="_blank" href={mhHref}>
            Access my highlights
          </styledMenu.Item>
        ) : null}
        {browseHref ? (
          <styledMenu.Item target="_blank" rel="noreferrer" href={browseHref}>
            Browse the book
          </styledMenu.Item>
        ) : null}
      </styledMenu.Button>
    </>
  );
}
