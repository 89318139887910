import React from "react";
import {
  NavBarMenuButton,
  NavBarMenuItem,
  colors,
} from "@openstax/ui-components";
import { useServices } from "../core/context/services";
import newTabIcon from "../assets/icons/new-tab.svg";
import styled from "styled-components";

export const styledMenu = {
  Button: styled(NavBarMenuButton)`
    color: ${colors.palette.gray};
    font-size: 1.4rem;
  `,
  Item: styled(NavBarMenuItem)`
    color: ${colors.palette.neutralDarker};
    text-decoration: none;

    :focus-visible {
      outline: 0;
      background: ${colors.palette.neutralLighter};
    }
    :hover {
      color: ${colors.palette.neutralDarker};
      text-decoration: none;
    }
  `,
};

function useUserId() {
  const [id, setId] = React.useState<number | undefined>();
  const authProvider = useServices().authProvider;

  React.useEffect(
    () => {
      authProvider.getUser().then((userData) => userData && setId(userData.id));
    },
    [authProvider]
  );

  return id;
}

const IframeWrapper = styled.div`
  background-color: #F5F5F5;
  position: absolute;
  width: 100%;
  top: 4rem;
  left: 0;
  bottom: 0;
  z-index: 20;
`;

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: calc(100% - 5rem);
`;

function PutAway({onClick, className}: {onClick: () => void; className?: string}) {
  return (
    <div className={className}>
      <button type="button" onClick={onClick} aria-label="close form">
          Back
      </button>
    </div>
  );
}

const StyledPutAway = styled(PutAway)`
  border-top: 0.1rem solid #D5D5D5;
  width: 100%;
  height: 5.6rem;
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  padding-left: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;

  @media(min-width: 56em) {
    padding: 0 calc(50vw - 43rem);
  }

  button {
    height: 3rem;
    background-color: white;
    border: 1px solid #D5D5D5;
    box-shadow: 0px 2px 4px 0px #00000033;
    width: 9rem;
    border-radius: 0.5rem;
  }
`;

const useHelpMenu = (actions: {label: string; callback: () => void}[] = []) => {
  const [showIframe, setShowIframe] = React.useState<string | undefined>();

  function HelpMenu({
    id,
  }: {
    id?: string;
  }) {
    const userId = useUserId();
    const contactFormUrl = React.useMemo(() => {
      const formUrl = "https://openstax.org/embedded/contact";
      const params = [
        id ? encodeURIComponent(`assignment_id=${id}`) : "",
        userId ? encodeURIComponent(`user_id=${userId}`) : "",
      ]
        .filter((s) => s.length)
        .map((p) => `body=${p}`)
        .join("&");

      return `${formUrl}?${params}`;
    }, [id, userId]);

    return (
      <>
        <styledMenu.Button label="Help" aria-label="Help menu">
          {actions.map((action, i) => <styledMenu.Item key={i} onAction={action.callback}>
            {action.label}
          </styledMenu.Item>
          )}
          <styledMenu.Item
            onAction={() => setShowIframe(contactFormUrl)}
          >
            Report an issue
          </styledMenu.Item>
          <styledMenu.Item
            href="https://help.openstax.org/s/global-search/assignable"
            target="_blank"
          >
            Support center&nbsp;
            <img src={newTabIcon} alt="new tab" />
          </styledMenu.Item>
          <styledMenu.Item
            href="https://openstax.org/accessibility-statement"
            target="_blank"
          >
            Accessibility statement&nbsp;
            <img src={newTabIcon} alt="new tab" />
          </styledMenu.Item>
          <styledMenu.Item
            onAction={() => {
              window.parent.postMessage({type: 'TriggerConsentModalMessage'}, '*');
            }}
          >
            Cookie settings
          </styledMenu.Item>
        </styledMenu.Button>
      </>
    );
  }

  function ContactFormIframe() {
    React.useEffect(
      () => {
        const closeIt = ({data}: MessageEvent) => {
          if (data === 'CONTACT_FORM_SUBMITTED') {
            setShowIframe(undefined);
          }
        };

        window.addEventListener('message', closeIt, false);

        return () => window.removeEventListener('message', closeIt, false);
      },
      []
    );

    if (!showIframe) {
      return null;
    }

    return (
      <IframeWrapper>
        <Iframe title="Contact form" src={showIframe} />
        <StyledPutAway onClick={() => setShowIframe(undefined)} />
      </IframeWrapper>
    );
  }

  return [HelpMenu, ContactFormIframe];
};

export default useHelpMenu;
